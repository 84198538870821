<template>
  <div>
    <span v-if="feature.featureType === 'access' && feature.featureAddonQuantity !== 'true'">
      <el-switch v-model="feature.featureValue" active-color="#13ce66" inactive-color="#ff4949" active-value="true" inactive-value="false"></el-switch>
    </span>
    <span v-else-if="feature.featureType === 'info'">
      {{ feature.featureValue }}
    </span>
    <span v-else-if="feature.featureAddonQuantity !== 'true'">
      <el-input v-model.number="feature.featureValue"></el-input>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    feature: {
      required: true,
    },
  },
}
</script>
